<template>
  <component
    :is="computedTag"
    v-bind="$attrs"
    :class="[
      'ui-button',
      extraClasses,
      {
        'is-loading': loading,
        'is-full': expanded,
        'is-hover-disabled': hoverDisabled,
        'has-no-border': noBorder,
      },
    ]"
  >
    <span v-if="label" :class="`ui-button__label`">
      {{ label }}
    </span>
    <span v-else-if="$slots.default" :class="`ui-button__label`">
      <slot />
    </span>
  </component>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { computed, useAttrs, type PropType, toRefs } from 'vue';
import AppLink from '~/components/global/elements/AppLink';

type ButtonNativeType = 'button' | 'submit' | 'reset';
type ButtonTag = 'a' | 'button' | 'input' | 'app-link';

const props = defineProps({
  nativeType: {
    type: String as PropType<ButtonNativeType>,
    default: 'button',
  },
  tag: {
    type: String as PropType<ButtonTag>,
    default: 'button',
  },
  label: {
    type: String,
    default: null,
  },
  loading: Boolean,
  modifier: {
    type: [String, Array],
    default: '',
  },
  expanded: Boolean,
  noBorder: Boolean,
  hoverDisabled: {
    type: Boolean,
    default: false,
  },
});

const { tag } = toRefs(props);
const attrs = useAttrs();

const computedTag = computed(() => {
  if (attrs.disabled !== undefined && attrs.disabled !== false) {
    return 'button';
  }
  if (tag.value === 'app-link') {
    return AppLink;
  }
  return tag.value;
});

const extraClasses = computed(() => {
  if (!props.modifier) {
    return null;
  }
  return (Array.isArray(props.modifier) ? props.modifier : [props.modifier]).map((mod) => `is-${mod}`);
});
</script>

<style lang="scss" scoped>
@use '@/assets/mixins/__index' as mix;

.ui-button {
  @include mix.unselectable;
  padding: 0 calc-clamp(320, $desktop, 30, 45);
  height: calc-clamp(320, $desktop, 45, 50);
  @include fluid(font-size, 16, 17, 18);
  position: relative;
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  text-transform: capitalize;
  white-space: nowrap;
  color: var(--secondary);
  font-weight: 500;
  font-family: var(--body-font-family);
  border: #{rem(3)} solid var(--primary);
  border-radius: rem(99);
  background-color: var(--primary);
  @include transition;

  &:hover {
    background-color: transparent;
    border-color: var(--primary);
    color: var(--primary);
  }

  &.is-no-hover {
    &:hover {
      background-color: var(--primary);
      color: var(--secondary);
    }
  }

  &.is-outlined {
    background-color: transparent;
    border-color: var(--primary);
    color: var(--primary);
    &:hover {
      background-color: var(--primary);
      color: var(--secondary);
      border-collapse: var(--primary);
    }
  }

  &__label {
    flex: 1;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    place-items: center;
    place-content: center;
    gap: rem(15);

    svg {
      width: rem(40);
    }
  }

  &.is-loading {
    pointer-events: none;
    cursor: wait;
  }

  &.is-full {
    display: flex;
    width: 100%;
  }

  &.is-narrow {
    padding: 0 calc-clamp(320, $desktop, 20, 30);
  }

  &.is-small {
    padding: 0 calc-clamp(320, $desktop, 20, 30);
    height: calc-clamp(320, $desktop, 35, 45);
    @include fluid(font-size, 15, 16, 17);
  }

  &.is-wide {
    padding: 0 calc-clamp(320, $desktop, 45, 60);
    &.is-small {
      padding: 0 calc-clamp(320, $desktop, 30, 40);
    }
  }

  .has-primary-bg & {
    background-color: var(--secondary);
    border-color: var(--secondary);
    color: var(--primary);

    @include hover {
      background-color: transparent;
      color: var(--secondary);
      border-color: var(--secondary);
    }
  }

  &.is-white {
    background-color: var(--white);
    border-color: var(--white);
    color: var(--primary);

    @include hover {
      background-color: transparent;
      color: var(--white);
      border-color: var(--white);
    }
  }

  &.is-secondary {
    background-color: var(--darker-pink);
    border-color: var(--darker-pink);
    color: var(--white);

    @include hover {
      background-color: transparent;
      color: var(--darker-pink);
      border-color: var(--darker-pink);
    }

    &.is-outlined {
      background: transparent;
      border-color: var(--dark-pink);
      color: var(--dark-pink);
      @include hover {
        background-color: var(--dark-pink);
        color: var(--primary);
        border-color: var(--dark-pink);
      }
    }
  }

  &:active,
  &.is-active {
    background-color: transparent;
    color: var(--primary);
  }

  &.is-light {
    background: var(--secondary);
    color: var(--primary);
    border-color: var(--primary);

    @include hover {
      background-color: var(--primary);
      border-color: var(--secondary);
      color: var(--secondary);
    }
  }

  &[disabled],
  &.is-disabled {
    cursor: not-allowed;
    opacity: 0.4;
    background-color: var(--black);
    border-color: var(--black);
    color: var(--white);
  }

  &.has-no-border {
    border: 0;
  }
}
</style>
